import { isJapanese } from 'helpers/locale'
import styled, { css, CSSProp } from 'styled-components'

const ArticleFooter = styled.div`
  ${({ theme }): CSSProp => css`
    padding: 8rem 0 15rem;
    @media only screen and ${theme.breakpoints.toNormalScreen} {
      padding: 7rem 0;
    }
    .heading {
      ${isJapanese() ?
        css`
          font-family: ${ theme.font.fontFamilyDefault };
          font-weight: ${ theme.font.fontWeightBold };
        `
        : css`font-family: ${ theme.font.fontFamilyAlt };`
      };
      font-size: ${theme.font.fontSizeMid};
      margin-bottom: 2.5rem;
    }
    .cards {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 2rem;
      @media only screen and ${theme.breakpoints.fromNormalScreen} {
        grid-gap: 3rem;
        grid-template-columns: 1fr 1fr 1fr;
      }
    }
    h3:not(:first-child) {
      margin-top: 7rem;
    }
    .next {
      @media only screen and ${theme.breakpoints.toNormalScreen} {
        background-color: ${theme.colours.lightTint3};
        margin-left: -2rem;
        margin-right: -2rem;
        padding: 6rem 2rem 7rem;
      }
    }
  `}
`

export default ArticleFooter
